import {useEffect, useState} from "react";
import LeftMenuPage from "../Pub/Component/LeftMenuPage/LeftMenuPage";
import {LaptopOutlined, UserOutlined} from "@ant-design/icons";
import BotManager from "./BotManager/BotManager";
import UserManager from "./UserManager/UserManager";
import {myDomain} from "../Pub/PubVar";
import FetchParent from "../Pub/FetchParent";
function AdminDashboard(props) {
    //定义菜单项
    const menuItems = [
        { key: '1', title: '机器人管理', icon: <UserOutlined /> ,page:<BotManager setCurrentPage={props.setCurrentPage}/>},
        { key: '3', title: '通知管理', icon: <UserOutlined /> ,page:<UserManager/>},
        { key: '2', title: '待开发', icon: <LaptopOutlined />,
            children: [
                {
                    key: '21',
                    title: 'Submenu 2-1',
                    page:<UserManager/>,
                },
                {
                    key: '2-2',
                    title: 'Submenu 2-2',
                    page:<UserManager/>,
                },
            ],
        },
        // { key: '3', title: '选项1', icon: null },
        // { key: '4', title: '选项2', icon: null },
        // ...
    ];
    async function handleSubmitClick() {
        // console.log(values);
        // 创建一个 FormData 对象来包含表单数据
        const formData = new FormData();
        formData.append('test', 1);
        const url = `${myDomain}/api/checkLoginStatus`;
        //请求服务器获取用户信息,没有信息默认跳转到登录页面
        const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
        // if(data!==null && data!==undefined && data.message ==='Success'){
        //     //成功登录 跳转到首页
        //     props.setCurrentPage('AdminDashboard');
        // }
    }
    useEffect(() => {
        handleSubmitClick();
    }, []);

    return (
        <div>
            <LeftMenuPage menuItems={menuItems} setCurrentPage={props.setCurrentPage}/>
        </div>
    )
}

export default AdminDashboard;