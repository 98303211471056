import {myDomain} from "../../../Pub/PubVar";
import React, {useEffect, useState} from "react";
import FetchParent from "../../../Pub/FetchParent";
import SelectTable from "../../../Pub/Component/List/SelectTable";
import {Dialog} from "antd-mobile";
import ListColumnsGet from "./ListBotInfoDataColumns";
import BotModelAddShow from "../AddUpdateShowModel/BotModelAddShow";
import BotModelUpdateShow from "../AddUpdateShowModel/BotModelUpdateShow";

function ListBotInfo(props) {
    //数据源
    const [dataSource, setDataSource] = useState([]);
    //更新机器人页面是否显示
    const [updateModelShow, setUpdateModelShow] = useState(false)
    //更新的时候选择的行
    const [selectRecord,setSelectRecord] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    //获取数据
    async function getDataSource() {
        // setLoading(true);
        // 创建一个 FormData 对象来包含表单数据
        const formData = new FormData();
        formData.append('test', 1);
        const url = `${myDomain}/api/listTelegramBot`;
        const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
        // setLoading(false);
        if(data!==null && data.message ==='Ok'){
            console.log(data.data)
            //添加成功提示给用户看
            setDataSource(data.data);
            return true;
        }
        return false;
    }

    //删除的Fetch
    async function deleteFetch(props) {
        console.log('deleteCick!',props)
        // setLoading(true);
        // 创建一个 FormData 对象来包含表单数据
        const formData = new FormData();
        formData.append('delete_id', props);
        const url = `${myDomain}/api/deleteTelegramBot`;
        const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
        // setLoading(false);
        if(data!==null){
            //成功登录 返回用户需要的数据
            console.log('删除数据结果!',data.data)
            //添加成功提示给用户看
            Dialog.alert({
                content: <pre>{data.data}</pre>,
            });
            getDataSource();
            return true;
        }
        return false;
    }
    //更新弹出窗口
    const editClick = (props) => {
        console.log('editClick!',props)
        setIsEdit(true);
        setUpdateModelShow(true);
        setSelectRecord(props);
    };

    //加载的时候默认获取服务器
    useEffect(() => {
        getDataSource();
    }, [selectRecord]); // A
    return (
        <div>
            {/*添加数据的模块*/}
            <BotModelAddShow getDataSource={getDataSource}/>
            {/*更新模块*/}
            <BotModelUpdateShow getDataSource={getDataSource}
                                updateModelShow={updateModelShow}
                                setUpdateModelShow={setUpdateModelShow}
                                selectRecord={selectRecord}
                                isEdit={isEdit}
            />
            {/*显示机器人信息的列表包含删除模块的*/}
            <SelectTable  dataSource={dataSource}
                          ListBotInfoDataColumns={ListColumnsGet({editClick:editClick})}
                          deleteCick={deleteFetch}
            />
        </div>
    )
}

export default ListBotInfo;