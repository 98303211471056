import {Button, Popconfirm, Table} from "antd";
import React, {useEffect, useState} from "react";
import '../../../../Pub/Component/List/ListDataPub'
import {Dialog} from "antd-mobile";
/**
 * 渠道优先级顺序表
 */
const ChannelOrderTable = (props) => {




    return (
        <div  >
            <Table
                     dataSource={props.dataSource}
                     columns={props.ListBotInfoDataColumns}
                     scroll={{ x:600, y: 500 }} // 设置横向和纵向滚动

            />
            {/*<Popconfirm title="确定要删除吗?" onConfirm={deleteCick}>*/}
            {/*    <Button type="primary"  >*/}
            {/*        删除*/}
            {/*    </Button>*/}
            {/*</Popconfirm>*/}
        </div>
    )
}

export default ChannelOrderTable;