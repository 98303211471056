import {MyNavBar} from "../../../MyComponent/MyNavBar";
import {Button} from "antd-mobile";
import React, {useEffect, useState} from "react";
import {myTgWebAppDomain} from "../../../Pub/PubVar";
import MyFetch from "../../Pub/MyFetch";
import SelectTable from "../../../Pub/Component/List/SelectTable";
import ChannelColumns from "../ChannelColumns";
import ChannelDistributeColumns from "./ChannelDistributeColumns";
import ShowStartEndAmountTable from "./ShowStartEndAmountTable";
import ChannelOrderMain from "./ChannelOrder/ChannelOrderMain";

const ChannelDistributeMain = ({ handleSelectedChannelMenu,selectedChannelMenu}) => {
    //数据源
    const [dataSource, setDataSource] = useState([]);
    const [record, setRecord] = useState({}); //选择的记录
    async function getDataSource() {
        // setLoading(true);
        // 创建一个 FormData 对象来包含表单数据
        const formData = {
            test: 1
        };
        const url = `${myTgWebAppDomain}/api/webapp/getStartAndEndAmountList`;
        const response= await MyFetch({ url: url, formData: formData });
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData !== '') {
                setDataSource(responseData.data);
                return true;
            }
        } else {
            console.error('Request failed with /api/webapp/getStartAndEndAmountList:', response.message);
        }
        return false;
    }

    //删除的Fetch
    async function deleteFetch(props) {
        // console.log('deleteCick!',props)
        // // setLoading(true);
        // // 创建一个 FormData 对象来包含表单数据
        // const formData = new FormData();
        // formData.append('delete_id', props);
        // const url = `${myDomain}/api/deleteTelegramBot`;
        // const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
        // // setLoading(false);
        // if(data!==null){
        //     //成功登录 返回用户需要的数据
        //     console.log('删除数据结果!',data.data)
        //     //添加成功提示给用户看
        //     Dialog.alert({
        //         content: <pre>{data.data}</pre>,
        //     });
        //     getDataSource();
        //     return true;
        // }
        return false;
    }
    const editClick = (props) => {
        console.log('editClick!',props)
        // setIsEdit(true);
        // setUpdateModelShow(true);
        // setSelectRecord(props);
    };
    //加载的时候默认获取服务器
    useEffect(() => {
        getDataSource();
    }, []); // A

    const StartEndAmmountMain = (props) => {
        return(
        <div>
            <MyNavBar back={back} context={"渠道分发管理"}/>
            {/*首页显示基本菜单*/}
            <div>
                {/*显示机器人信息的列表包含删除模块的*/}
                <ShowStartEndAmountTable  dataSource={dataSource}
                                          ListBotInfoDataColumns={ChannelDistributeColumns({editClick:editClick})}
                                          deleteCick={deleteFetch}
                                          handleSelectedChannelMenu={props.handleSelectedChannelMenu}
                                          setRecord={setRecord}
                />
            </div>
        </div>
        )
        // setIsEdit(true);
        // setUpdateModelShow(true);
        // setSelectRecord(props);
    };
    const back = () => handleSelectedChannelMenu({page:'/home'}); //渠道的首页,有两个按钮
    return (
        <div>
            {selectedChannelMenu.page === '/channelDistribute' && <StartEndAmmountMain handleSelectedChannelMenu={handleSelectedChannelMenu}/>}
            {selectedChannelMenu.page === '/channelOrderMain' && <ChannelOrderMain handleSelectedChannelMenu={handleSelectedChannelMenu} record={record}/>}
        </div>
    )
}

export default ChannelDistributeMain;