import { Button } from 'antd-mobile'
import {useState} from "react";

function Message({ onMenuClick }) {

    return (
        <h2>Message</h2>
    );
}
export default Message;
