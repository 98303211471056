
import {AutoComplete, Button, Checkbox, Input, InputNumber, Radio, Select, Table} from "antd"
import {useState} from "react";
import {myTgWebAppDomain} from "../../../../Pub/PubVar";
import MyFetch from "../../../Pub/MyFetch";
const ChannelOrderColumns =(allChannelDdataSource,reFreshData,setSelectedRowKey,selectedRowKey)=>{
    const { Option } = Select;
    const [weight, setWeight] = useState();

    const handleWeightChange = async (value, record) => {
        // 在这里处理输入的值和 record 对象
        console.log('Value:', value);
        console.log('Record:', record);
        // 进行其他操作，如更新组件状态等
        const formData = {
            ID: record.ID,
            weight: value,
            status: '1'
        };
        const url = `${myTgWebAppDomain}/api/webapp/updateTgWebAppChannelOrderWeiht`;
        const response = await MyFetch({url: url, formData: formData});
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData.data === 1) {
                // reFreshData();
            }
        } else {
            console.error('Request failed with /api/webapp/updateTgWebAppChannelOrderWeiht:', response.message);
        }
    };
//数据源
    const handleChannelChange = (record, option) => {
        // 在这里处理record和option对象
        console.log('Record:', record);
        console.log('Option:', option);
        updateRecord(record, option);
        // 调用其他函数或执行其他操作
    };
    //更新每一行的数据
    const updateRecord = async (record, option) => {
        // 在这里处理record和option对象
        console.log('Record:', record);
        console.log('Option:', option);
        // 调用其他函数或执行其他操作
        // setLoading(true);
        // 创建一个 FormData 对象来包含表单数据
        const formData = {
            ID: record.ID,
            channel_id: option.key,
            status:'1'
        };
        const url = `${myTgWebAppDomain}/api/webapp/updateTgWebAppChannelOrderChannelId`;
        const response = await MyFetch({url: url, formData: formData});
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData.data === 1) {
                // reFreshData();
            }
        } else {
            console.error('Request failed with /api/webapp/updateTgWebAppChannelOrderChannelId:', response.message);
        }
        return false;
    };



    const radioChange = (record) => {
        return () => {
            console.log("点击了单选按钮", record);
            setSelectedRowKey(record.ID);
        };
    };

    return [
        {
            title: '渠道名称',
            dataIndex: 'CHANNEL_NAME',
            key: 'CHANNEL_NAME',
            width: 120, // 设置列宽度:1000,

            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Radio onClick={radioChange(record)} checked={record.ID === selectedRowKey} />
                    <Select
                        defaultValue={text}
                        onSelect={(value, option) => handleChannelChange(record, option)}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp="children"
                        style={{ minWidth: '140px',maxWidth: '140px' }}
                        // 设置最小长度
                    >
                        {allChannelDdataSource.map(option => (
                            <Option key={option.PARTY_ID} value={option.NAME}>{option.NAME}</Option>
                        ))}
                    </Select>
                </div>
            ),

        },
        {
            title: '权重',
            dataIndex: 'WEIGHT',
            key: 'WEIGHT',
            width: 70,

            render: (text, record) => (
                <InputNumber
                    style={{ minWidth:'70px',maxWidth: '70px'}}
                    // style={{ flex: '1 1 auto', minWidth: '40px', maxWidth: '40px', marginLeft: '0px' }}
                    defaultValue={parseInt(record.WEIGHT)} // 将默认值转换为整数
                    type="number"
                    step={1}
                    onChange={(value) => handleWeightChange(value, record)}
                />
            ),

        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 160,

            render: (text, record) => (
                <Select
                    defaultValue={record.STATUS}
                    options={[
                        { value: 1, label: '正常', disabled: true },
                        { value: 0, label: '不可用', disabled: true },
                        { value: 2, label: '故障', disabled: true },
                        { value: 3, label: '放回测', disabled: true },
                    ]}
                    status={record.STATUS === 1 ? 'normal' : 'error' }
                />
            ),

        },

    ];
}

export default ChannelOrderColumns;