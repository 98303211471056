import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
    UserOutlined,
    LaptopOutlined,
    NotificationOutlined,
    SettingOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

const Sidebar = ({ selectedMenu, onMenuClick, menuItems,setCurrentPage }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    };

    const handleClick = (e) => {
        if (e.key === '800') {
            toggleMenu();
        } else if (e.key === '801') {
            //go to logout
            window.location.href = '/logout';
        } else {
            onMenuClick(e.key);
            // toggleMenu();
        }
    };

    const renderMenuItems = (items) => {
        return items.map((menuItem) => {
            if (menuItem.children && menuItem.children.length > 0) {
                return (
                    <Menu.SubMenu key={menuItem.key} icon={menuItem.icon} title={menuItem.title}>
                        {renderMenuItems(menuItem.children)}
                    </Menu.SubMenu>
                );
            } else {
                return (
                    <Menu.Item key={menuItem.key} icon={menuItem.icon}>
                        {menuItem.title}
                    </Menu.Item>
                );
            }
        });
    };

    return (
        <div>
            <Button
                onClick={toggleMenu}
                style={{
                    position: 'fixed',
                    top: '16px',
                    right: '16px',
                    zIndex: 500,
                    marginBottom: '16px',
                    display: collapsed ? 'block' : 'none',
                }}
            >
                {collapsed ? '展开' : '收起'}
            </Button>
            <Sider
                width={collapsed ? 80 : 150}
                theme="dark"
                collapsed={collapsed}
                style={{
                    minHeight: '1000vh',
                    display: collapsed ? 'none' : 'block',
                }}
            >
                <Menu
                    mode="inline"
                    theme="dark"
                    selectedKeys={[selectedMenu]}
                    onClick={handleClick}
                    defaultOpenKeys={[]}
                >
                    {renderMenuItems(menuItems)}
                    <Menu.Item key="800" icon={<SettingOutlined />}>
                        收起菜单
                    </Menu.Item>
                    <Menu.Item key="801" icon={<LaptopOutlined />}>
                        退出登录
                    </Menu.Item>

                </Menu>
            </Sider>
        </div>
    );
};

export default Sidebar;