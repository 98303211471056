import BotModelAddShow from "./AddUpdateShowModel/BotModelAddShow";
import ListBotInfo from "./ListBot/ListBotInfo";

function BotManager(props) {

    return (
        <div>
            <center>
                <h2>机器人管理页面</h2>
            </center>

            {/*显示机器人信息的列表*/}
            <ListBotInfo setCurrentPage={props.setCurrentPage}/>
        </div>
    )
}

export default BotManager;