import { Button } from 'antd-mobile';
import { useState } from "react";

function MyName({ onMenuClick, state }) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100vh',
            backgroundColor: '#f5f5f5'
        }}>
            <h2 style={{
                fontSize: '24px',
                color: '#333',
                textAlign: 'center',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#fff'
            }}>名字: {state.firstName}</h2>
        </div>
    );
}

export default MyName;