import "./ListDataPub.css"

import React, {useEffect} from "react";
import {Table} from "antd";

/**
 * 列表的公共类
 */
function ListDataPub(props) {
    const {dataSource, columns} = props;
    useEffect(() => {
        console.log('listDataSource',dataSource)
    }, [dataSource]);
        return(<>
            {/*显示列表*/}
            <Table dataSource={dataSource} columns={columns} />;
        </>);
}

export default ListDataPub;