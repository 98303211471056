import { Button } from 'antd-mobile'
import {useState} from "react";

function Home({ onMenuClick }) {

    return (
        <h2>机器人APP</h2>
    );
}
export default Home;
