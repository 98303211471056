import { Button, Select, Table } from "antd";

const ChannelDistributeColumns = (props) => {
    return [
        // Table.SELECTION_COLUMN,
        // {
        //     display: 'none',
        //     title: 'ID',
        //     dataIndex: 'key',
        //     key: 'key',
        //     // 设置样式来隐藏列
        //     style: {display: 'none'},
        // },

        {
            title: '起始金额',
            dataIndex: 'START_AMOUNT',
            key: 'startAmount', // 添加唯一的 key 属性
        },

        {
            title: '结束金额',
            dataIndex: 'END_AMOUNT',
            key: 'endAmount', // 添加唯一的 key 属性
        },
    ];
};

export default ChannelDistributeColumns;