import { Button } from 'antd-mobile'
import React, {useEffect, useState} from "react";

import {MyNavBar} from "../../../../MyComponent/MyNavBar";
import {myTgWebAppDomain} from "../../../../Pub/PubVar";
import MyFetch from "../../../Pub/MyFetch";
import ChannelDistributeColumns from "../ChannelDistributeColumns";
import ShowStartEndAmountTable from "../ShowStartEndAmountTable";
import ChannelOrderColumns from "./ChannelOrderColumns";
import ChannelOrderTable from "./ChannelOrderTable";
import Icon from "antd/es/icon";
import {AddCircleOutline, LoopOutline, MinusCircleOutline} from "antd-mobile-icons";


function ChannelOrderMain({handleSelectedChannelMenu,record}) {
    const [refreshNum,setRefreshNum] = useState();

    //数据源
    const [dataSource, setDataSource] = useState([]);
    //渠道数据
    const [allChannelDdataSource, setallChannelDdataSource] = useState([]);

    const [selectedRowKey, setSelectedRowKey] = useState(null); //选中哪一行需要删除的
    async function getDataSource() {
        // setLoading(true);
        // 创建一个 FormData 对象来包含表单数据
        const formData = {
            END_AMOUNT: record.END_AMOUNT,
            START_AMOUNT: record.START_AMOUNT
        };
        const url = `${myTgWebAppDomain}/api/webapp/getChannelOrderList`;
        const response= await MyFetch({ url: url, formData: formData });
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData !== '') {
                setDataSource(responseData.data);
                return true;
            }
        } else {
            console.error('Request failed with /api/webapp/getChannelOrderList:', response.message);
        }
        return false;
    }
    //获取渠道数据
    async function getAllChannelData() {
        // setLoading(true);
        // 创建一个 FormData 对象来包含表单数据
        const formData = {
            END_AMOUNT: record.END_AMOUNT,
            START_AMOUNT: record.START_AMOUNT
        };
        const url = `${myTgWebAppDomain}/api/webapp/getAllChannelIdAndName`;
        const response= await MyFetch({ url: url, formData: formData });
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData !== '') {
                setallChannelDdataSource(responseData.data);
                return true;
            }
        } else {
            console.error('Request failed with /api/webapp/getAllChannelIdAndName:', response.message);
        }
        return false;
    }
    //加载的时候默认获取服务器
    useEffect(() => {
        reFreshData();
    }, [setRefreshNum]); // A
    function reFreshData(){
        getDataSource();
        getAllChannelData();
        console.log("刷新数据;!!!")
    }

    const editClick = (props) => {
        console.log('editClick!',props)
        // setIsEdit(true);
        // setUpdateModelShow(true);
        // setSelectRecord(props);
    };

    //删除的Fetch
    async function deleteFetch(props) {
        // console.log('deleteCick!',props)
        // // setLoading(true);
        // // 创建一个 FormData 对象来包含表单数据
        // const formData = new FormData();
        // formData.append('delete_id', props);
        // const url = `${myDomain}/api/deleteTelegramBot`;
        // const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
        // // setLoading(false);
        // if(data!==null){
        //     //成功登录 返回用户需要的数据
        //     console.log('删除数据结果!',data.data)
        //     //添加成功提示给用户看
        //     Dialog.alert({
        //         content: <pre>{data.data}</pre>,
        //     });
        //     getDataSource();
        //     return true;
        // }
        return false;
    }
    const back = () => handleSelectedChannelMenu({page:'/channelDistribute'});
    const HomePage = () => {
        return (
            <div>
                <MyNavBar back={back} context={"渠道优先级管理"}/>
                {/*首页显示基本菜单*/}

            </div>
        )
    }

    const handleAddClick = async () => {
        // 处理添加按钮点击事件的逻辑
        console.log('Add button clicked');
        // 创建一个 FormData 对象来包含表单数据
        const formData = {
            END_AMOUNT: record.END_AMOUNT,
            START_AMOUNT: record.START_AMOUNT
        };
        const url = `${myTgWebAppDomain}/api/webapp/addTgWebAppChannelOrder`;
        const response = await MyFetch({url: url, formData: formData});
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData.data === 1) {
                reFreshData();
            }
        } else {
            console.error('Request failed with /api/webapp/addTgWebAppChannelOrder:', response.message);
        }
    };
    //删除渠道
    const handleMinusClick = async () => {
        // 处理减少按钮点击事件的逻辑
        console.log('Minus button clicked');
        // 创建一个 FormData 对象来包含表单数据
        const formData = {
            ID: selectedRowKey,
        };
        const url = `${myTgWebAppDomain}/api/webapp/deleteTgWebAppChannelOrder`;
        const response = await MyFetch({url: url, formData: formData});
        if(response===null){
            return false;
        }
        if (response.ok) {
            const responseData = await response.json(); // 解析 JSON 数据
            console.log(responseData); // 输出服务器返回的数据
            // 处理服务器返回的数据，例如更新组件状态等
            if (responseData.data === 1) {
                reFreshData();
                handleRefreshClick();
            }
        } else {
            console.error('Request failed with /api/webapp/deleteTgWebAppChannelOrder:', response.message);
        }
    };

    const handleRefreshClick = () => {
        setDataSource(null);
        setRefreshNum(new Date().getTime());
        reFreshData();
        // 处理刷新按钮点击事件的逻辑
        console.log('Refresh button clicked');
        // 在这里执行刷新逻辑
    };

    return (
        <div>
            {/*返回按钮设置*/}
            <HomePage />
            <AddCircleOutline fontSize={26} color='var(--adm-color-primary)' onClick={handleAddClick}/>
            <span style={{ margin: '0 10px' }}></span>
            <MinusCircleOutline fontSize={26} color='var(--adm-color-danger)' onClick={handleMinusClick}/>
            <span style={{ margin: '0 10px' }}></span>
            <LoopOutline
                type="reload"
                style={{ fontSize: 26, color: 'var(--adm-color-primary)' }}
                onClick={handleRefreshClick}
            />

            <br />
            <ChannelOrderTable  dataSource={dataSource}
                                      ListBotInfoDataColumns={ChannelOrderColumns(allChannelDdataSource,
                                                                                            reFreshData,
                                                                                                        setSelectedRowKey,
                                                                                                        selectedRowKey)}
                                      deleteCick={deleteFetch}
                                      handleSelectedChannelMenu={handleSelectedChannelMenu}
                                refreshNum={refreshNum}
            />
        </div>
    );
}
export default ChannelOrderMain;
