
import {Button, Popconfirm, Table} from "antd";
import React, {useState} from "react";
import './ListDataPub.css'
import * as Alter from "antd-mobile/es/components/modal/show";
import {Dialog} from "antd-mobile";
/**
 * 公共类的demo
 */
const SelectTable = (props) => {
        const [selectedRowKeys, setSelectedRowKeys] = useState([]);
        // const [loading, setLoading] = useState(false);
        // 行选择配置
        const onSelectChange = (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            setSelectedRowKeys(newSelectedRowKeys);
        };
        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };
        const deleteCick = () => {
            if(selectedRowKeys.length===0){
                Dialog.alert({
                    content: <pre>{'请勾选要删除的记录'}</pre>,
                });
            }
            props.deleteCick(selectedRowKeys)
        }

    return (
            <div>
                <Table rowSelection={rowSelection} dataSource={props.dataSource} columns={props.ListBotInfoDataColumns} />
                <Popconfirm title="确定要删除吗?" onConfirm={deleteCick}>
                    <Button type="primary"  >
                        删除
                    </Button>
                </Popconfirm>
            </div>
        )
}

export default SelectTable;