import React, {useState} from 'react';
import { Layout } from 'antd';
import { HomeOutlined, InfoCircleOutlined, ToolOutlined, ContactsOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import './TgWebHome.css';
import BottomMenu from "./Menu/BottomMenu";
import Home from "./Home/Home";
import Message from "./Message/Message";
import ChannelMain from "./ChannelManager/ChannelMain";
import MyName from "./MyName/MyName";

const { Footer } = Layout;

const TgWebHome = (props) => {
    const [selectedMenu, setSelectedMenu] = useState({page:'/home'}); //用户点击的菜单项 全局用的
    const handleMenuClick = (params) => {
        // 处理菜单点击事件，更新选中的菜单项
        setSelectedMenu(params);
        console.log('Index Clicked on menu item:', params);
        // console.log('Index Clicked on menu record:', record);
        // setRecord(record);
    };
    return (
        <Layout className="layout-container">
            <div className='topNavigation'>
                {selectedMenu.page === '/home' && <BottomMenu onMenuClick={handleMenuClick}/>}

            <div className='menuBody'>
                {/*把点击函数当成参数传过去导航用到的*/}
                {selectedMenu.page === '/home' && <Home onMenuClick={handleMenuClick}/>}
                {selectedMenu.page === '/channelMain' && <ChannelMain onMenuClick={handleMenuClick}/>}
                {selectedMenu.page === '/order' && <Message onMenuClick={handleMenuClick}/>}
                {selectedMenu.page === '/me' && <MyName onMenuClick={handleMenuClick} state={props.state}/>}
                {/*  购买商品页面*/}
                {selectedMenu.page === '/productiveItem' && <Message onMenuClick={handleMenuClick} selectedItem={selectedMenu.item}/>}
                {selectedMenu.page === '/OrderConfirmationPay' && <Message onMenuClick={handleMenuClick} selectedItem={selectedMenu.item}/>}
                {selectedMenu.page === '/OrderManager' && <Message onMenuClick={handleMenuClick} selectedItem={selectedMenu.item}/>}
                {selectedMenu.page === '/OrderSuccess' && <Message onMenuClick={handleMenuClick} selectedItem={selectedMenu.item}/>}

            </div>
            </div>
        </Layout>
    );
};

export default TgWebHome;