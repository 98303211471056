import React from 'react';
import { Layout, Menu, Button } from 'antd';
import Sidebar from "./Sidebar";
import {Content, Header} from "antd/es/layout/layout";

/**
 * 左边是菜单的公共类
 */
class LeftMenuPage extends React.Component {
    //构造方法
    constructor(props) {
        super(props);
        this.state = {
            // 初始化组件的状态
            count: 0,
            selectedMenu: '1',
            selectedGameRecord: null,
        };
    }
    render() {
        // 使用 this.props 访问属性
        // 使用 this.state 访问状态
        const { menuItems,BotManager,setCurrentPage } = this.props;
        const { selectedMenu,  selectedGameRecord } = this.state;
        const handleMenuClick = (key,page,record) => {
            // 处理菜单点击事件，更新选中的菜单项
            console.log('Index Clicked on menu item:', key);
            console.log('Index Clicked on menu record:', record);
            // setRecord(record);
            // 处理菜单点击事件，更新选中的菜单项
            this.setState({
                selectedMenu: key,
                page:page,
                // selectedGameRecord: record
            });
        };
        const renderSubMenuPages = (subMenuItems, selectedMenu) => {
            const selectedItem = subMenuItems.find(item => item.key === selectedMenu);
            if (selectedItem) {
                return selectedItem.page;
            }

            for (const item of subMenuItems) {
                if (item.children) {
                    const foundPage = renderSubMenuPages(item.children, selectedMenu);
                    if (foundPage) {
                        return foundPage;
                    }
                }
            }

            return null;
        };
        return (
            <div>
                <Layout  >
                    <Sidebar
                             onMenuClick={handleMenuClick}
                             menuItems={menuItems}
                             setCurrentPage={setCurrentPage}
                             style={{ height: '1000vh', maxWidth: 600 }}/>
                    <Layout>

                        <Content style={{ margin: '24px 16px 2' }}>
                                <div style={{ padding: 0,   minHeight: 60000 }}>
                                    {/* 根据选中的菜单项显示不同的组件 */}
                                    {renderSubMenuPages(menuItems, selectedMenu)}
                                </div>
                                {/*{selectedMenu === '12' && <ProductManager onMenuClick={handleMenuClick} selectedGameRecord={selectedGameRecord} />}*/}
                                {/*    {selectedMenu === '2' && <Unknown />}*/}
                                {/*    {selectedMenu === '99' && <MobileAuthorityTable onMenuClick={handleMenuClick} record={record}/>}*/}
                                {/*    {selectedMenu === '101' && <MyMobileSmsReport onMenuClick={handleMenuClick} record={record}/>}*/}
                                {/*    {selectedMenu === '102' && <MobileStatusReport onMenuClick={handleMenuClick} record={record}/>}*/}

                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}


export default LeftMenuPage;