
import {useEffect, useState} from "react";
function UserManager() {
    return (
        <div>
            <h1>UserManager</h1>
        </div>
    )
}

export default UserManager;