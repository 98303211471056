
import BotModelItemShow from "./BotModelItemShow";
import FetchParent from "../../../Pub/FetchParent";
import {myDomain} from "../../../Pub/PubVar";
import { Form} from "antd";
import AddModelForm from "../../../Pub/Component/ModelForm/AddModelForm";
import {Dialog} from "antd-mobile";
import UpdateModelForm from "../../../Pub/Component/ModelForm/UpdateModelForm";
import {useEffect, useState} from "react";

/**
 * 添加机器人的Model
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function BotModelUpdateShow(props) {

        //点击确定按钮的时候调用的事件
        async function handleUpdateClick(values) {
            // 创建一个 FormData 对象来包含表单数据
            const formData = new FormData();
            formData.append('key', values.key);
            formData.append('bot_name', values.bot_name);
            formData.append('bot_api_token', values.bot_api_token);
            formData.append('is_test', values.is_test);
            const url = `${myDomain}/api/updateBot`;
            const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
            if(data!==null && data.message ==='Ok'){
                //添加成功提示给用户看
                Dialog.alert({
                    content: <pre>{data.data}</pre>,
                }).then(() => {
                    props.setUpdateModelShow(false);
                    props.getDataSource();
                    console.error('对话框点击了确定');
                    }).catch((error) => {
                    // 处理错误，如果有的话
                    console.error('对话框显示出错:', error);
                });

                return true;
            }
            return false;
        }
        const [form] = Form.useForm()
        useEffect(() => {
            form.resetFields();
            form.setFieldsValue(props.selectRecord);
        }, [props.selectRecord,props.isEdit]); // A

        return (
            <>
                <UpdateModelForm
                               FormItemShow={() => BotModelItemShow({isEdit:props.isEdit})} // 要显示的表单
                               title="更新机器人"
                               modelWidth={400}
                               handleSubmitClick={handleUpdateClick} //提交事件
                               form={form}                    //表格
                               closable={true}                      //是否可以关闭
                               isEdit={false} //是否编辑,默认为false不填充数据
                               updateModelShow={props.updateModelShow}
                               setUpdateModelShow={props.setUpdateModelShow}
                />
            </>
        )
}
export default BotModelUpdateShow;