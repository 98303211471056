import {Dialog} from "antd-mobile";


async function MyFetch(props) {
    const{url,formData} = props;
    try{
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
            withCredentials: true
        });
        return response;
    }catch (error){
        Dialog.alert({
            content: <pre>{'网络服务器异常,不能访问!'}</pre>,
        })
        return null;
    }

}

export default MyFetch