import {myDomain, ifeyLoginStatus} from "./PubVar";
import {Dialog} from "antd-mobile";


async function FetchParent(props) {
    const{url,formData,setCurrentPage} = props;
    try{
        // console.log(values);
        // 创建一个 FormData 对象来包含表单数据
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            credentials: 'include', // 将此选项设置为 'include'，以便包含浏览器的 cookie
        });
        const data = await response.json();
        console.log("fetchServiceData responseData",data);
        if(response.status ===200){
            //成功登录 返回用户需要的数据
            return data;
        }
        //没有权限跳转到登录页面
        else if(response.status ===201){
            setCurrentPage('LoginModal');
            return null;
        }
        //202 用作要显示data信息给用户看 比如用户名和密码错误
        else if(response.status ===202){
            Dialog.alert({
                content: <pre>{data.data}</pre>,
            })
            return null;
        }
        else{
            return null;
        }
    }catch (error){
        Dialog.alert({
            content: <pre>{'网络服务器异常,不能访问!'}</pre>,
        })
        return null;
    }

}

export default FetchParent