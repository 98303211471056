
import BotModelItemShow from "./BotModelItemShow";
import FetchParent from "../../../Pub/FetchParent";
import {myDomain} from "../../../Pub/PubVar";
import { Form} from "antd";
import AddModelForm from "../../../Pub/Component/ModelForm/AddModelForm";
import {Dialog} from "antd-mobile";

/**
 * 添加机器人的Model
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function BotModelAddShow(props) {
        //点击确定按钮的时候调用的事件
        async function handleSubmitClick(values) {
            // 创建一个 FormData 对象来包含表单数据
            const formData = new FormData();
            formData.append('bot_name', values.bot_name);
            formData.append('bot_api_token', values.bot_api_token);
            formData.append('is_test', values.is_test);
            const url = `${myDomain}/api/creatbot`;
            const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
            if(data!==null && data.message ==='Ok'){
                //添加成功提示给用户看
                Dialog.alert({
                    content: <pre>{data.data}</pre>,
                })
                props.getDataSource();
                return true;
            }
            return false;
        }
        const [form] = Form.useForm()
        return (
            <>
                <AddModelForm
                               FormItemShow={BotModelItemShow}  //要显示的表单
                               title="添加机器人"
                               modelWidth={400}
                               handleSubmitClick={handleSubmitClick} //提交事件
                               form={form}                    //表格
                               closable={true}                      //是否可以关闭
                               isEdit={false} //是否编辑,默认为false不填充数据
                />
            </>
        )
}
export default BotModelAddShow;