import { Button } from 'antd-mobile'
import React, {useState} from "react";
import {MyNavBar} from "../../MyComponent/MyNavBar";
import ListBotInfo from "../../AdminDashboard/BotManager/ListBot/ListBotInfo";
import LisChannelInfo from "./LisChannelInfo";
import ChannelDistributeMain from "./ChannelDistribute/ChannelDistributeMain";
import ChannelOrderMain from "./ChannelDistribute/ChannelOrder/ChannelOrderMain";

function ChannelMain({ onMenuClick }) {
    const [selectedChannelMenu, setselectedChannelMenu] = useState({page:'/home'}); //用户点击的菜单项 全局用的
    const handleSelectedChannelMenu = (params) => {
        // 处理菜单点击事件，更新选中的菜单项
        setselectedChannelMenu(params);
        console.log('selectedChannelMenu:', params);
    };

    const channelDistributeClick = (params) => {
        // 处理菜单点击事件，更新选中的菜单项
        setselectedChannelMenu({page:'/channelDistribute'});
        console.log('点击了渠道分发:', params);
    };

    const HomePage = () => {
        return (
            <div>
                <MyNavBar back={back} context={"渠道管理"}/>
                {/*首页显示基本菜单*/}
                <Button size='middle' color='primary' onClick={channelDistributeClick}>
                    渠道分发
                </Button>
            </div>
        )
    }
    const back = () => onMenuClick({page:'/home'});
    return (
        <div>
            {/*返回按钮设置*/}
            {selectedChannelMenu.page === '/home' && <HomePage />}

            {(selectedChannelMenu.page === '/channelDistribute' ||
                selectedChannelMenu.page === '/channelOrderMain')&&
                <ChannelDistributeMain   handleSelectedChannelMenu={handleSelectedChannelMenu}
                                         selectedChannelMenu={selectedChannelMenu}/>}

        </div>
    );
}
export default ChannelMain;
