import React from "react";
import ShowModelForm from "./ShowModelForm";
import {Button} from "antd";

/**
 * 公共类
 * 添加数据的模板
 */
class UpdateModelForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // 初始化组件的状态
            ShowAddModel:props.updateModelShow
        };
    }
    render() {
        //获取传入的参数
        const {title,FormItemShow,modelWidth,handleSubmitClick,form,closable,isEdit,setUpdateModelShow,updateModelShow} = this.props;
        //点击添加按钮操作
        const AddButtonClickHandle = () => {
            form.resetFields();
            this.setState({
                ShowAddModel: true,
            });
            setUpdateModelShow(true);
        }
        const modelCancleHandle = () => {
            this.setState({
                ShowAddModel: false,
            });
            setUpdateModelShow(false);
            form.resetFields();
        }
        const modelHandleSubmitClick = (values) => {
            if(handleSubmitClick(values)){
                this.setState({
                    ShowAddModel: false,
                });
            }
            form.resetFields();
        }

        return<>
            <ShowModelForm
                FormItemShow={FormItemShow}  //要显示的表单
                title={title}
                modelWidth={modelWidth}
                handleSubmitClick={modelHandleSubmitClick} //提交事件
                form={form}                    //表格
                visiable={updateModelShow}        //是否显示
                closable={closable}                      //是否可以关闭
                modelCancleHandle={modelCancleHandle} //关闭事件
                isEdit={isEdit} //是否编辑,默认为false不填充数据
            />
        </>
    }
}

export default UpdateModelForm;