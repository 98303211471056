import React, {ReactNode, useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {Dialog} from "antd-mobile";

/**
 * showmodel的公共模板
 */
class ShowModelForm extends React.Component {
    //formLayout;
    constructor(props) {
        super(props);
        this.state = {
            // 初始化组件的状态
            count: 0,
        };
    }
    render() {
        const { FormItemShow ,title,modelWidth,handleSubmitClick,form,visiable,closable,modelCancleHandle,isEdit} = this.props;
        function GetFormContent(){
            const handleSubmit=async () => {
                const values = await form.validateFields();
                // Dialog.alert({
                //     content: <pre>{JSON.stringify(values, null, 2)}</pre>,
                // })
                handleSubmitClick(values);
            }
            // const [form] = Form.useForm()

            return (
                <div style={{ display: 'flex', justifyContent: 'center',width: '100%' }} >
                    <Form  form={form} onFinish={handleSubmit}
                           style={{ width: '100%' }} width={500}
                           labelCol={{
                               span: 6,
                           }}
                           wrapperCol={{ span: 20 }}
                           align="center"
                    >
                    <FormItemShow isEdit={isEdit}/>
                    </Form>
                </div>
            )
        }
        return (
            <>
                <Modal
                    title={<h4 style={{ textAlign: 'center' }}>{title}</h4>}
                    closable={closable}
                    visible={visiable}
                    footer={null}
                    width={modelWidth} // 设置Modal的宽度为400px
                    onCancel={modelCancleHandle}
                >
                    <GetFormContent />
                </Modal>
            </>
        );
    }
}
export default ShowModelForm;