import telegramApp from 'telegram-web-app';
import {Component} from "react";
import {myDomain, myTgWebAppDomain} from "../Pub/PubVar";
import TgWebHome from "./TgWebHome";
import MyFetch from "./Pub/MyFetch";


class TgWebApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            firstName: '',
            lastName: '',
            username: '',
            platform: '',
            userType: ''
        };
    }
    async componentDidMount() {
        //这个是node.js处理机器人的代码
        //     telegramApp.init({
        //         apiKey: '6583470259:AAH-ZHiuJEaxMmNZnWLSNkstEFnBxoic7kg',
        //         onLogin: (user) => {
        //             this.setState({
        //                 userId: user.id,
        //                 firstName: user.firstName,
        //                 lastName: user.lastName,
        //                 username: user.username
        //             });
        //         }
        //     });
        //用户登录的信息通过参数传过来了如下:
        //#tgwebapp?tgWebAppData=user={"id":5860047689,"first_name":"ifey","last_name":"chan","username":"IfeyChan","language_code":"en","allows_write_to_pm":true}&chat_instance=-1536316224324412348&chat_type=private&auth_date=1710398823&hash=c8a09de16609adf833cd6c8479cf00ce82e9159c201a7c91e667dc71041cc860&tgWebAppVersion=7.0&tgWebAppPlatform=tdesktop&tgWebAppThemeParams={"accent_text_color":"#168acd","bg_color":"#ffffff","button_color":"#40a7e3","button_text_color":"#ffffff","destructive_text_color":"#d14e4e","header_bg_color":"#ffffff","hint_color":"#999999","link_color":"#168acd","secondary_bg_color":"#f1f1f1","section_bg_color":"#ffffff","section_header_text_color":"#168acd","subtitle_text_color":"#999999","text_color":"#000000"}
        //获取telegram 用户的信息
        try {

            const path = window.location.hash;
            if(path===""){
                // 在组件加载后执行初始化 Telegram Web App SDK
                // login
                return;
            }
            let decodedPath = path;
            while (true) {
                const temp = decodeURIComponent(decodedPath);
                if (temp === decodedPath) {
                    break;
                }
                decodedPath = temp;
            }
            // 解析 decodedPath 中的参数值
            const regex = /tgWebAppData=user=(.*?)&/;
            const match = regex.exec(decodedPath);
            const decodedData = decodeURIComponent(match[1]);
            const jsonData = JSON.parse(decodedData);

            const userId = jsonData.id;
            const username = jsonData.username;
            const firstName = jsonData.first_name;
            const lastName = jsonData.last_name;


            const regex2 = /tgWebAppPlatform=(.*?)&/;
            const match2 = regex2.exec(decodedPath);
            const platform = match2[1];

            this.setState({
                userId: userId,
                username: username,
                firstName: firstName,
                lastName: lastName,
                platform: platform
            });

            //然后会把每一个登录进来的用户通过 api 插入数据
            //实时的数据提交方法

            // 创建一个 FormData 对象来包含表单数据
            const data = {
                userId: userId,
                username: username,
                firstName: firstName,
                lastName: lastName,
                platform: platform
            };
            const url = `${myTgWebAppDomain}/api/webapp/getTgWebbAppUserInfo`;
            const response= await MyFetch({ url: url, formData: data });
            if (response.ok) {
                const responseData = await response.json(); // 解析 JSON 数据
                console.log(responseData); // 输出服务器返回的数据
                // 处理服务器返回的数据，例如更新组件状态等
                if (responseData !== '') {
                    this.setState({
                        userType: responseData.data
                    });
                }
            } else {
                console.error('Request failed with status:', response.status);
            }
            console.log(response.text);
        } catch (error) {
            console.error('Error setOpenValue game:', error);
        }
    }
    render() {
        const {userType } = this.state;

        return (
            <div>
                {userType === "" && <h2>加载中...</h2>}
                {userType === "0" && <h2>没有权限，请联系管理员！</h2>}
                {userType !== "" && userType !== "0" && parseInt(userType) >= 1 && <TgWebHome state={this.state} />}
            </div>
        );
    }
}

export default TgWebApp;