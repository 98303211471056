import {Button, Popconfirm, Table} from "antd";
import React, {useState} from "react";
import '../../../Pub/Component/List/ListDataPub'
import {Dialog} from "antd-mobile";
/**
 * 公共类的demo
 */
const ShowStartEndAmountTable = (props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // const [loading, setLoading] = useState(false);
    // 行选择配置
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const deleteCick = () => {
        if(selectedRowKeys.length===0){
            Dialog.alert({
                content: <pre>{'请勾选要删除的记录'}</pre>,
            });
        }
        props.deleteCick(selectedRowKeys)
    }

    const handleRowClick = (record) => {
        // 在这里处理行点击事件
        console.log("点击了行",record,record.START_AMOUNT, record.END_AMOUNT);
        props.setRecord(record);
        props.handleSelectedChannelMenu({page:'/channelOrderMain'})
    };

    const rowProps = (record) => {
        return {
            onClick: () => {
                handleRowClick(record);
            },
        };
    };

    return (
        <div>
            <Table   dataSource={props.dataSource}
                     columns={props.ListBotInfoDataColumns}
                     onRow={(record) => rowProps(record)} />
            {/*<Popconfirm title="确定要删除吗?" onConfirm={deleteCick}>*/}
            {/*    <Button type="primary"  >*/}
            {/*        删除*/}
            {/*    </Button>*/}
            {/*</Popconfirm>*/}
        </div>
    )
}

export default ShowStartEndAmountTable;