import React, { useState } from 'react';
import styles  from './Menu.css';
import {
    UserOutlined,
    LaptopOutlined,
    NotificationOutlined,
    SettingOutlined, OrderedListOutlined,
} from '@ant-design/icons';
import {AppOutline, MessageOutline, UnorderedListOutline, UserOutline,BillOutline,BellOutline} from "antd-mobile-icons";
import {TabBar} from "antd-mobile";

const BottomMenu = ({onMenuClick }) => {
    const setRouteActive = (value) => {
        console.log("index click="+value)
        onMenuClick({page:value})
    }
    const tabs = [
        {
            key: '/home',
            title: '首页',
            icon: <AppOutline />,
        },
        {
            key: '/channelMain',
            title: '渠道设置',
            icon: <BellOutline  />,
        },
        {
            key: '/order',
            title: '订单',
            icon: <OrderedListOutlined />,
        },
        {
            key: '/me',
            title: '我的',
            icon: <UserOutline />,
        },
    ]
    const handleClick = (e) => {
        if(e.key==='800'){

        }
        else if(e.key==='801'){
            //go to logout
            window.location.href = "/logout";
        }
        else{
            onMenuClick(e.key);

        }
    };
    return (
        <TabBar /*activeKey={pathname} */ onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
};

export default BottomMenu;
