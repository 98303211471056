import {Button, Form, Input} from "antd";
import React from "react";

function FormItemShow(){
    return(<>
        <Form.Item
            // label="用户名"
            hint="请输入用户名"
            name="login_name"
            rules={[{ required: true, message: '请输入用户名' }]}
            align="center"
        >
            <Input placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item
            // label="密码"
            name="login_password"
            rules={[{ required: true, message: '请输入密码' }]}
            align="center"
        >
            <Input type="password" placeholder="请输入密码"/>
        </Form.Item>
        <Form.Item align="center">
            <Button type="primary" htmlType="submit"  >
                登录
            </Button>
        </Form.Item>
    </>)
}
export default FormItemShow