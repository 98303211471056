import {Button, Select, Table} from "antd";

const ListColumnsGet =(props)=>{
    const ListBotInfoDataColumns = [
        Table.SELECTION_COLUMN,
        {
            display: 'none',
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
            // 设置样式来隐藏列
            style: { display: 'none' },
        },

        {
            title: '机器人名字',
            dataIndex: 'bot_name',
            key: 'bot_name',
        },

        {
            title: '机器人api_token',
            dataIndex: 'bot_api_token',
            key: 'bot_api_token',
        },
        {
            title: '是否测试机器人',
            dataIndex: 'is_test',
            key: 'is_test',
            render: (text, record) => (
                <Select
                        value={text}
                        style={{ width: 120 }}
                        options={[
                            { value: 'y', label: '是', disabled: true },
                            { value: 'n', label: '否', disabled: true },
                        ]}
                />
            ),
        },

        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Button type="link" loading={false} onClick={() => props.editClick(record)}>
                    编辑
                </Button>
            ),
        },
    ];
    return ListBotInfoDataColumns;
}


export default ListColumnsGet;