import {Button, Form, Input, Row, Select} from "antd";
import React from "react";

function BotModelItemShow(props) {
    return(<>
        {props.isEdit ? (<><Form.Item
            label="商品ID"
            name="key"
            style={{ display: 'none'}}
            rules={[{ required: false,message: '用户ID为空' }]}
        >
            <Input />
        </Form.Item></>) : (<></>)}
        <Form.Item
            label="名字"
            hint="请输入机器人名字"
            name="bot_name"
            rules={[{ required: true, message: '请输入机器人名字' }]}
        >
            <Input placeholder="请输入机器人名字" />
        </Form.Item>
        <Form.Item
            label="TOKEN"
            hint="请输入机器人API_TOKEN"
            name="bot_api_token"
            rules={[{ required: true, message: '请输入机器人API_TOKEN' }]}
        >
            <Input placeholder="请输入机器人API_TOKEN" />
        </Form.Item>

        <Form.Item
            label="测试"
            hint="是否是测试机器人"
            name="is_test"
            rules={[{ required: true, message: '是否是测试机器人' }]}
        >
            <Select
                options={[
                    { value: 'y', label: '是', disabled: false },
                    { value: 'n', label: '否', disabled: false },
                ]}
            />
        </Form.Item>

        <Form.Item align="center">
            <Button type="primary" htmlType="submit" align="center">
                确定
            </Button>
        </Form.Item>
    </>)
}
export default BotModelItemShow