import {useEffect, useState} from "react";
import LeftMenuPage from "../Pub/Component/LeftMenuPage/LeftMenuPage";
import {LaptopOutlined, UserOutlined} from "@ant-design/icons";
import BotManager from "../AdminDashboard/BotManager/BotManager";
import UserManager from "../AdminDashboard/UserManager/UserManager";

function ApiMain() {
    const menuItems = [
        { key: '1', title: '游戏接口', icon: <UserOutlined /> ,page:<BotManager/>},
        { key: '3', title: '回调接口', icon: <UserOutlined /> ,page:<UserManager/>},
        { key: '2', title: '待开发', icon: <LaptopOutlined />,
            children: [
                {
                    key: '21',
                    title: 'Submenu 2-1',
                    page:<UserManager/>,
                },
                {
                    key: '2-2',
                    title: 'Submenu 2-2',
                    page:<BotManager/>,
                },
            ],
        },
        // { key: '3', title: '选项1', icon: null },
        // { key: '4', title: '选项2', icon: null },
        // ...
    ];
    return (
        <div>
            <LeftMenuPage menuItems={menuItems}/>
        </div>
    )
}

export default ApiMain;