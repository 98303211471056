
import ShowModelForm from "../../Pub/Component/ModelForm/ShowModelForm";
import FormItemShow from "./FormItemShow";
import {myDomain, ifeyLoginStatus} from "../../Pub/PubVar";
import {getCookieByName, setCookie} from "../../Pub/Cookie";
import {Dialog} from "antd-mobile";
import FetchParent from "../../Pub/FetchParent";
import {Form} from "antd";

function LoginModal(props) {
        //点击确定按钮的时候调用的事件
        async function handleSubmitClick(values) {
            // console.log(values);
            // 创建一个 FormData 对象来包含表单数据
            const formData = new FormData();
            formData.append('username', values.login_name);
            formData.append('password', values.login_password);
            formData.append('remember-me', 'on');
            const url = `${myDomain}/api/apilogin`;
            const data = await FetchParent({ url: url, formData: formData ,setCurrentPage:props.setCurrentPage});
            if(data!==null && data.data ==='Success'){
                //成功登录 跳转到首页
                props.setCurrentPage('AdminDashboard');
            }
        }
        function modelCancleHandle(){

        }
        const [form] = Form.useForm()
        return (
            <>
                <ShowModelForm FormItemShow={FormItemShow}
                               title="登录"
                               modelWidth={300}
                               handleSubmitClick={handleSubmitClick}
                               form={form}
                               visiable={true}
                               closable={false}
                               modelCancleHandle={modelCancleHandle}
                               isEdit={false}/>
            </>
        )
}
export default LoginModal;